.employee-form {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Arial, sans-serif;
  }
  
  .input-field {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding and border are included in the width */
  }
  
  .input-field:focus {
    outline: none;
    border-color: dodgerblue; /* Change border color on focus */
  }
  .add-employee-btn-container {
    display: flex;
    justify-content: flex-end; /* Aligns content to the right */
  }
  /* Employee.css */


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  z-index: 999; /* Ensure it's on top of other content */
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
/* .nested-dropdown {
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 300px;
}

.nested-dropdown h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.nested-dropdown label {
  display: block;
  margin-bottom: 5px;
} */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  
  