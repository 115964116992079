.withdraw-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.withdraw-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
}

.withdraw-table th,
.withdraw-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.withdraw-table th {
    background-color: #f4f4f4;
    color: #333;
}

.withdraw-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.withdraw-table tr:hover {
    background-color: #f1f1f1;
}

.approve-btn {
    background-color: #4CAF50;
    border-radius: 5px;
    margin: 5px;
    /* Green */
}

.reject-btn {
    background-color: #f44336;
    border-radius: 5px;
    margin: 5px;
    /* Red */
}

.processing-btn {
    background-color: #ff9800;
    border-radius: 5px;
    margin: 5px;
    /* Orange */
}

.rollback-btn {
    background-color: #2196F3;
    border-radius: 5px;
    margin: 5px;
    /* Blue */
}

button:disabled {
    background-color: #ccc;
    /* Light grey when disabled */
    cursor: not-allowed;
    border-radius: 5px;
    margin: 5px;
}

button {
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

button:hover:enabled {
    opacity: 0.8;
    /* Slightly reduce opacity on hover */
}


.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination span {
    font-size: 16px;
    margin: 0 10px;
}

.loader-container {
    width: 100%;
    height: 100vh;
    /* Full viewport height for centering on the page */
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    border: 4px solid #f3f3f3;
    /* Light gray */
    border-top: 4px solid #007bff;
    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}