@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
.Login-bg {
    height: 100vh;
    background-color: white;
    padding: 6rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
}
.form-container {
    background-color:#fff;
    padding: 2rem;
    width: 500px !important;
    transform: scale(0.9);
    box-shadow: 0px 0px 20px #198754;

}
.form-container  h1 {
    text-align: center;
    font-family: 'ABeeZee', sans-serif;
    font-weight: 700;
}
.form-container svg{
    text-align: center !important;
    font-size: 80px;
    margin-left: 45%;
    margin-top: 10px;
}
.form-container form {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
}
.form-container form .form-label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5rem;

}
.form-container form .input-group-text {
    background-color: #198754;
    color: #fff;
}
.form-container form input[type="text"]:focus,
.form-container form input[type="password"]:focus,
.form-container form .uneditable-input:focus {
    border-color: #198754!important; 
    box-shadow: 0 1px 1px #4bf1a4 inset, 0 0 8px #198754!important; 
    outline: 0 none;

}
.form-container form button {
    width: 100%;
}
@media  (max-width: 768px) {
    .form-container {
       width: 400px !important; 
       padding: 1rem;
       margin-top: 4rem;
    }
    .form-container form {
        padding: 0 !important;
        
    }
    .Login-bg {
        padding: 0.5rem;
    }
}
