.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999; /* Higher z-index to overlay other content */
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #767676;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 30%;
  left: 30%;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  background: rgba(130, 26, 26, 0.5);

}

.modal-content {
  /* background: white; */
  background: red;

  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.pagination button.active {
  color: red;
  font-weight: bold;
}

  
