/* CSS for the AvatarScreen component as a table */

  
  .avatar-image {
    max-width: 100px; /* Adjust the max-width to control the image size */
    height:50px; /* Maintain aspect ratio */
    border-radius: 50%;
  }
  .Header {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
  /* styles.css */
.upload-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
  justify-content: end;

}

.upload-image input[type="file"] {
  display: none; /* Hide the file input */
}

.upload-image label {
  background-color: #cb341a;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 10px;
}

.upload-image label:hover {
  background-color: #923718;
}
/* AvatarScreen.css */
.avatar-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; /* Add spacing between rows */
}

.avatar-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: calc(20% - 10px); /* 20% width with margin between cards */
}

.avatar-card img {
  width: 70px;
  height: 70px;
  border-radius: 70%;
  margin: 10px;
}


  