.Header {
  background-color: #333;
  color: #fff;
  font-size: 24px;
  text-align: center;
  padding: 10px;
}
/* Add this CSS to your stylesheet */
.hobbies-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.hobbies-table th {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: left;
}

.hobbies-table td {
  border: 1px solid #ccc;
  padding: 10px;
}

.edit-button, .delete-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px; 

}

.edit-button:hover, .delete-button:hover {
  background-color: #0056b3;
}
