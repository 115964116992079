/* Profile container */
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #3498db; /* Header text color */
  text-align: center;
}

/* Add Money button and input section */
.money-section {
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-container input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.input-container button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.input-container button:hover {
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
}

/* Superadmin Details section */
.superadmin-details {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.superadmin-card {
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  color: #130f0f; /* Text color for better contrast */
  position: relative;
  overflow: hidden;
}

.superadmin-card:before {
  content: '';
}
/* ... (other styles) */

.money-section {
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-container input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.confirm-button,
.cancel-button {
  width: 48%; /* Adjust width as needed */
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
 
}

.confirm-button {
  background: linear-gradient(to bottom right, #ff7e5f, #feb47b);
  color: #fff;
}

.cancel-button {
  background: linear-gradient(to bottom right, #ff6347, #ff4500);
  color: #fff;
}

.confirm-button:hover,
.cancel-button:hover {
  filter: brightness(1.2); /* Adjust brightness on hover */
}

/* ... (other styles) */
/* ... (other styles) */

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ... (other styles) */
