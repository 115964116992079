@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');


/* .header{
  width: "100%";
  background-color:green;
  
} */

  #header p {
    margin-top: 12px;
  }
  #header p svg {
    color: #3bc0fd !important;
    margin-left: 7px;
    margin-top: 10px;
    transform: scale(1.5);
    padding: 2px;
    text-shadow: 0px 0px 2px #3bc0fd;
  }
  #header .pro-sidebar {
    height: 100vh;
  }
  #header .closemenu {
    color: #3bc0fd;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 44px;
    cursor: pointer;
    transform: scale(1.1);
  }
  #header .pro-sidebar {
    width: 100%;
    min-width: 100%;
 
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    background-color:  #f8f9fa;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  #header .pro-sidebar-inner a {
    color: black !important;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
    font-family: 'ABeeZee', sans-serif;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 10px 0px;
    font-family: 'ABeeZee', sans-serif;
    font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #fdfdfd;
    color: #000;
    border-radius: 3px;
    box-shadow: 0px 0px 2px black;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #3bc0fd 0%, #90d6ff 100%);
  }
  #header .logo {
    padding: 20px;
  }

  #header .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item  {
      background-color: #fff !important;
  }
  #header .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item  a {
    color: black !important;
  }
  #header .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
      background-color: #fff;
      box-shadow: 0px 0px 4px black;
      
  }
  
